import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import LargeHeroImageText from '../components/microsite/LargeHeroImageText'
import TilesBox from '../components/microsite/Tiles'
import ElementSplit from '../components/microsite/ElementSplit'
import ListComponent from '../components/microsite/ListComponent'
import CallToAction from '../components/microsite/CallToAction_hin'
import { isMobile } from 'react-device-detect'
import CallToActionMobile from '../components/microsite/CallToActionMobile'
import Contact from '../components/microsite/Contact'
import { useRef } from 'react'

// TODO: Maybe add a reroute to this and give this file an english name again for consistent naming?

const potentialsTiles = [
  {
    icon: 'fa-gavel',
    content: 'Entdeckung von Regelverstößen durch internes Frühwarnsystem.',
  },
  {
    icon: 'fa-wind',
    content: 'Verbesserung des Betriebsklimas durch mehr Transparenz.',
  },

  {
    icon: 'fa-trophy',
    content: 'Steigerung des Ansehens gegenüber Dritten.',
  },
  {
    icon: 'fa-balance-scale-right',
    content: 'Etablierung einer eigenen Compliance-Kultur.',
  },
  {
    icon: 'fa-user-secret',
    content: 'Schutz vor Reputationsverlusten.',
  },
  /* {
    icon: "fa-lightbulb",
    content: "Die Innovationsführerschaft ist abgesichert.",
  }, */
]

const colenioTiles = [
  {
    icon: 'fa-comments',
    content: 'Wir übernehmen die Meldestelle für Sie.',
  },
  {
    icon: 'fa-laptop',
    content:
      'Unsere webbasierte Meldestelle, mit der auch anonyme Kommunikation möglich ist.',
  },
  {
    icon: 'fa-people-arrows',
    content: 'Wir übernehmen die Kommunikation mit dem Hinweisgeber.',
  },
  {
    icon: 'fa-at',
    content: 'Unsere weiteren Meldekanäle: Telefon, Post, Email.',
  },
  {
    icon: 'fa-comment-dots',
    content:
      'Unsere Meldestelle erweitert als Beschwerde- oder Berichtsstelle (LkSG; GwG; AGG).',
  },
  {
    icon: 'fa-gavel',
    content:
      'Rechtliche Beratung durch unsere angeschlossene Rechtsanwaltskanzlei, die Advokat Pro.',
  },
  {
    icon: 'fa-balance-scale-right',
    content:
      'Unsere langjährige Erfahrung im Bereich Compliance und Datenschutz.',
  },
]

const challenges = [
  {
    icon: 'fa-users',
    title: 'Unabhängigkeit',
    content:
      'Beeinflussung der Meldestelle durch die Unternehmensleitung vermeiden',
  },
  {
    icon: 'fa-university',
    title: 'Fachkunde',
    content: 'fachliche Kompetenz der zuständigen Mitarbeiter sicherstellen',
  },
  {
    icon: 'fa-lock',
    title: 'Schutz',
    content: 'Konsequenzen für Hinweisgeber und Beschäftigungsgeber',
  },
  {
    icon: 'fa-meh',
    title: 'Interessenkonflikte',
    content: 'Neutralität der Meldestelle gewährleisten',
  },
  {
    icon: 'fa-user-shield',
    title: 'Datenschutz',
    content: 'Vertraulichkeit personenbezogener Daten gewährleisten',
  },
]

const App = props => {
  const { data } = props
  const contactRef = useRef(null)
  const scrollToBottom = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout>
      <LargeHeroImageText
        title="Das Hinweisgeberschutzgesetz - eine Chance!"
        subtitle=""
        img={data.hero.childImageSharp}
      >
        Mit dem Hinweisgeberschutzgesetz (HinSchG) setzt der Gesetzgeber die
        Anforderungen aus der Whistleblower-Richtlinie (Richtlinie (EU)
        2019/1937) in deutsches Recht um – wir begleiten Sie dabei! <br></br>
        <br></br>
        Mit unserem Hinweisgebersystem setzen wir zusammen mit Ihnen die
        Anforderungen aus dem HinSchG um und unterstützen Sie mit unserer
        Expertise und Erfahrung bei der Bearbeitung von Meldungen Ihrer
        Beschäftigten oder auch von Dritten. So schützen Sie Ihr Unternehmen und
        reduzieren Ihre Risiken.
      </LargeHeroImageText>

      <ElementSplit
        headline="HinSchG – Nutzen Sie die Chance!"
        subline=""
      ></ElementSplit>

      <TilesBox
        img={data.parallax.childImageSharp}
        tiles={potentialsTiles}
      ></TilesBox>

      <ElementSplit
        headline="HinSchG – Ihre Herausforderungen! "
        subline=""
      ></ElementSplit>
      <ListComponent
        img={data.parallax2.childImageSharp}
        items={challenges}
      ></ListComponent>

      <ElementSplit
        headline="HinSchG – Unser Service für Sie!"
        subline="colenio ist Ihr kompetenter Partner für Ihr Hinweisgebersystem."
      ></ElementSplit>

      <TilesBox
        img={data.parallax3.childImageSharp}
        tiles={colenioTiles}
      ></TilesBox>

      <ElementSplit
        headline="HinSchG – Preise"
        subline="entsprechend der Unternehmensgröße."
      ></ElementSplit>

      <div class="pricing-table">
        <div class="pricing-plan">
          <div class="plan-header">&gt; 50 Beschäftigte</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">€</span>200
            </span>
            /Monat
          </div>
        </div>
        <div class="pricing-plan is-primary">
          <div class="plan-header">&gt; 100 Beschäftigte</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">€</span>250
            </span>
            /Monat
          </div>
        </div>
        <div class="pricing-plan is-active">
          <div class="plan-header">&gt; 250 Beschäftigte</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">€</span>350
            </span>
            /Monat
          </div>
        </div>
        <div class="pricing-plan is-link">
          <div class="plan-header">&gt; 500 Beschäftigte</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">€</span>500
            </span>
            /Monat
          </div>
        </div>
        <div class="pricing-plan is-success">
          <div class="plan-header">&gt; 1000 Beschäftigte</div>
          <div class="plan-price">
            <span class="plan-price-amount">
              <span class="plan-price-currency">€</span>800
            </span>
            /Monat
          </div>
        </div>
        <div class="pricing-plan">
          <div class="plan-header">&gt; 2500 Beschäftigte</div>
          <div class="plan-price">
            <span class="title is-3">
              Gerne erstellen wir Ihnen ein individuelles Angebot.
            </span>
          </div>
        </div>
      </div>

      {/* Dummy ref to enable scroll to view, cf. https://stackoverflow.com/a/41700815*/}
      <div ref={contactRef}></div>
      <ElementSplit
        headline="Unsere Kompetenzen für Sie!"
        subline="Vereinbaren Sie einen ersten und kostenfreien Beratungstermin mit uns."
      ></ElementSplit>
      <Contact
        img={data.contact.childImageSharp}
        name="Sven-Christian Dethlefsen"
        phone="+49 (0)175 – 379 11 77"
        mail="sven-christian.dethlefsen@colenio.de"
        position="Head of Legal | Rechtsanwalt"
        area="Datenschutz | Geldwäsche | Hinweisgeber"
      ></Contact>
      {/* Maybe move CTA into Contact? */}
      {isMobile ? (
        <CallToActionMobile onClick={scrollToBottom} />
      ) : (
        <CallToAction
          img={data.contact.childImageSharp}
          headline="Interesse an einem ersten Beratungstermin?"
        />
      )}
    </Layout>
  )
}

export default App

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "secret.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax: file(relativePath: { eq: "law.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax2: file(relativePath: { eq: "sea.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax3: file(relativePath: { eq: "formentor.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: NORTH }
        )
      }
    }
    podcast: file(relativePath: { eq: "podcast-iot-use-case.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          aspectRatio: 1.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    contact: file(relativePath: { eq: "sven-christian-dethlefsen.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          aspectRatio: 1
          transformOptions: { fit: COVER, cropFocus: NORTH }
        )
      }
    }
  }
`
