import React from 'react'
import * as styles from './microsite.module.sass'
import GatsbyFullCoverBgImage from '../GatsbyBgImage'

const CallToAction = ({ img, headline }) => {
  return (
    <div>
      <div className={styles.colenioCta}>
        <GatsbyFullCoverBgImage img={img}>
          {/* Placeholder div for background space */}
          <div className={styles.colenioCtaImage}></div>
        </GatsbyFullCoverBgImage>
        <p
          className={`has-text-white has-text-left ${styles.colenioCtaWrapper}`}
        >
          {headline}
          <br></br>| Sven-Christian Dethlefsen
          <br></br>| Head of Legal | Rechtsanwalt
          <br></br>| +49 (0)175 – 379 11 77 /
          sven-christian.dethlefsen@colenio.de
        </p>
        <div className={`${styles.colenioCtaWrapper}`}>
          <div>
            <a href="mailto:sven-christian.dethlefsen@colenio.de?subject=Hinweisgeberschutzgesetz">
              <i class="fas fa-envelope is-size-3 has-text-primary"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
